<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline" @keyup.enter.native="handleQuery">
          <a-row :gutter="48">
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="手机号" prop="mobile">-->
            <!--                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="邮箱" prop="email">-->
            <!--                <a-input v-model="queryParam.email" placeholder="请输入邮箱" allow-clear/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <a-col :md="5" :sm="12">
              <a-form-item label="昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear/>
              </a-form-item>
            </a-col>


            <a-col :md="5" :sm="12">
              <a-form-item label="是否会员" prop="sex">
                <a-select placeholder="请选择是否会员" v-model="queryParam.isVip" allow-clear>
                  <a-select-option :value="0">否</a-select-option>
                  <a-select-option :value="1">是</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">

              <a-col :md="5" :sm="24">
                <a-form-item label="用户来源" prop="type">
                  <a-select placeholder="请选择用户来源" v-model="queryParam.type" allow-clear>
                    <a-select-option :value="0">facebook</a-select-option>
                    <a-select-option :value="1">google</a-select-option>
                    <a-select-option :value="2">apple</a-select-option>
                    <a-select-option :value="3">tiktok</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="5" :sm="24">
                <a-form-item label="会员过期时间" prop="vipOverTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.vipOverTime" format="YYYY-MM-DD HH:mm:ss"
                                 allow-clear/>
                </a-form-item>
              </a-col>

              <a-form-item label="用户地址" prop="address">
                <a-input v-model="queryParam.address" placeholder="请输入用户地址" allow-clear/>
              </a-form-item>

              <a-col :md="5" :sm="24">
                <a-form-item label="注册时间" prop="createTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createTime" format="YYYY-MM-DD HH:mm:ss"
                                 allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 12" :sm="12">
              <!--              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">-->
              <a-button type="primary" @click="handleQuery">
                <a-icon type="search"/>
                查询
              </a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">
                <a-icon type="redo"/>
                重置
              </a-button>
              <a @click="toggleAdvanced" style="margin-left: 2px">
                {{ advanced ? '收起' : '展开' }}
                <a-icon :type="advanced ? 'up' : 'down'"/>
              </a>
              <!--              </span>-->

            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :sexOptions="dict.type.sys_user_sex"
        :isNoticeOptions="dict.type.sys_notice_status"
        :statusOptions="dict.type.sys_enable_disable"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="sex" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_user_sex']" :value="record.sex"/>
        </span>
        <span slot="isNotice" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice"/>
        </span>


        <span slot="status" slot-scope="text, record">
          <a-switch checkedChildren="正常" unCheckedChildren="禁用" v-model="record.status == 1"
                    @change="changeIsOpen($event,record)"/>
        </span>

        <span slot="operation" slot-scope="text, record">

          <a @click="$refs.Bill.getOrderList(record.id)">充值订单</a>
          <a-divider type="vertical"/>
              <a @click="$refs.vipOrder.getOrderList(record.id)">会员订单</a>
        </span>


        <!--        <span slot="status" slot-scope="text, record">-->
        <!--          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status"/>-->
        <!--        </span>-->
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="vipOverTime" slot-scope="text, record">
          <div v-if(record.vipOverTime)>
            {{ parseTime(record.vipOverTime) }}
          </div>
          <div else>{{ '-' }}</div>
        </span>

      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
        :rowKey="(record, index) => index + 1"
      />

      <Bill ref="Bill"></Bill>
      <VipOrder ref="vipOrder"></VipOrder>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageUser, listUser, delUser, changeStatus} from '@/api/biz/user'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import moment from "moment";
import Bill from "@/views/biz/user/modules/Bill";
import VipOrder from "@/views/biz/user/modules/VipOrder";

export default {
  name: 'User',
  components: {
    VipOrder,
    Bill,
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['sys_user_sex', 'sys_notice_status', 'sys_enable_disable'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        email: null,
        nickname: null,
        vipOverTime: null,
        isVip: null,
        sex: null,
        invitationCode: null,
        isNotice: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '三方账号',
        //   dataIndex: 'mobile',
        //   align: 'center'
        // },
        // {
        //   title: '邮箱',
        //   dataIndex: 'email',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '序号',
          // ellipsis: true,,
          customRender: (text, record, index) => index + 1,
          width: '120',
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          width: '120',
          align: 'center',
          customRender: function (text) {
            if (text) {
              return text
            } else {
              return '-'
            }
          }
        },
        // {
        //   title: '性别',
        //   dataIndex: 'sex',
        //   scopedSlots: { customRender: 'sex' },
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        // {
        //   title: '生日',
        //   dataIndex: 'birthday',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '邀请码',
          dataIndex: 'invitationCode',
          width: '120',
          align: 'center'
        },
        {
          title: '禁用状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'},
          // ellipsis: true,,
          align: 'center'
        },
        // {
        //   title: '用户状态',
        //   dataIndex: 'status',
        //   scopedSlots: { customRender: 'status' },
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '是否会员',
          dataIndex: 'isVip',
          width: '120',
          align: 'center',
          customRender: function (t, r, index) {
            if (t == 0) {
              return '否'
            } else if (t == 1) {
              return '是'
            }
          }
        },


        {
          title: '用户来源',
          dataIndex: 'type',
          width: '120',
          align: 'center',
          customRender: function (t, r, index) {
            if (t == 0) {
              return 'facebook'
            } else if (t == 1) {
              return 'google'
            } else if (t == 2) {
              return 'apple'
            } else if (t == 3) {
              return 'tiktok'
            } else {
              return '未知'
            }
          }
        },


        {
          title: '会员过期时间',
          dataIndex: 'vipOverTime',
          scopedSlots: {customRender: 'vipOverTime'},
          width: '120',
          align: 'center'
        },
        {
          title: '用户地址',
          dataIndex: 'address',
          width: '120',
          align: 'center',
          customRender: function (text) {
            if (text) {
              return text
            } else {
              return '-'
            }
          }
        },

        // {
        //   title: '金币数',
        //   dataIndex: 'gold',
        //   width: '120',
        //   align: 'center'
        // },
        {
          title: '钻石数',
          dataIndex: 'diamond',
          width: '120',
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          width: '120',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询用户信息列表 */
    getList() {
      this.loading = true
      if (this.queryParam.vipOverTime) {
        if (moment.isMoment(this.queryParam.vipOverTime)) {
          this.queryParam.vipOverTime = this.queryParam.vipOverTime.format('YYYY-MM-DD')
        }
      }

      if (this.queryParam.createTime) {
        if (moment.isMoment(this.queryParam.createTime)) {
          this.queryParam.createTime = this.queryParam.createTime.format('YYYY-MM-DD')
        }
      }
      pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    changeIsOpen(e, record) {
      record.status = e ? 1 : 0
      changeStatus(record).then(response => {
        this.$message.success(
          '操作成功'
        )
        return record.status = e ? 1 : 0
      })
    },


    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickname: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.queryParam.pageNum = 1
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        //content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('biz/user/export', {
            ...that.queryParam
          }, `用户信息_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
